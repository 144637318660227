export const branchOffices = [
    [
        {type: 'Text', label: 'Nombre', value: 'name'},
        {type: 'Text', label: 'Descripcion', value: 'description'},
        {
            type: 'SelectInput',
            label:'Estado',
            value:'active',
            displayValue: 'description',
            valueSelect: 'value',
            choices:[
                {description:'Activo', value:'true'},
                {description:'Inactivo', value:'false'}
            ], displayList:false
        },
        {type: 'EditButton', displayUpdate: false, displayCreate: false}
    ]
];

export const workers = [
    [
        {type: 'Text', label: 'Primer nombre', value: 'first_name'},
        {type: 'Text', label: 'Segundo nombre', value: 'last_name'},
        {type: 'Text', label: 'Correo', value: 'email'},
        {type: 'Text', label: 'Telefono', value: 'phone'},
        {type: 'ReferenceSelect', label: 'Sucursales', value: 'branch_office_id', reference: {service: 'branch-offices', value: 'name'}},
        {type: 'Text', label: 'Estado', value: 'active', displayUpdate: false, displayCreate: false},
        {
            type: 'SelectInput',
            label:'Estado',
            value:'active',
            displayValue: 'description',
            valueSelect: 'value',
            choices:[
                {description:'Activo', value:'true'},
                {description:'Inactivo', value:'false'}
            ], displayList:false
        },
        {type: 'EditButton', displayUpdate: false, displayCreate: false},
        {type: 'DeleteButton', displayUpdate: false, displayCreate: false}
    ]
];

export const registers = [
    [
        {type: 'ImageField', label: 'Avatar', value: 'avatar'},
        {type: 'Text', label: 'Nombre', value: 'name'},
        {type: 'FullNameField' },
        {type: 'Identification'}
        // {type: 'ReferenceSelect', label: 'Trabajador Primer Nombre', value: 'worker_id', reference: {service: 'workers', value: 'first_name'}},
        // {type: 'ReferenceSelect', label: 'Trabajador Segundo Nombre', value: 'worker_id', reference: {service: 'workers', value: 'last_name'}}
    ]
];

export const users = [
    [
        {type: 'Text', label: 'email', value: 'email'},
        {type: 'TextFieldPassword', label: 'password', value: 'password', displayList: false, displayShow: false},
        {
            type: 'SelectInput',
            label:'Rol',
            value:'role',
            displayValue: 'description',
            valueSelect: 'value',
            choices:[
                {description:'Administrador', value:'admin'},
                {description:'Basico', value:'basic'}
            ]
        }
    ]
];
