import React, {Component} from 'react';
import {Admin, Resource} from "react-admin";
import {restClient, authClient} from 'ra-data-feathers';
import {rest} from '../utils/connections/feathers';
import {routes} from './base-routes';
import {Create, List, Show, Update, ListFilter} from './components/generic-components';
import './App.css';

const restClientOptions = {
    id: '_id', // In this example, the database uses '_id' rather than 'id'
    usePatch: true // Use PATCH instead of PUT for updates
};

const authClientOptions = {
    storageKey: 'feathers-jwt', // The key in localStorage used to store the authentication token
    authenticate: { // Options included in calls to Feathers client.authenticate
        strategy: 'local', // The authentication strategy Feathers should use
    },
    permissionsKey: 'role', // The key in localStorage used to store permissions from decoded JWT
    permissionsField: 'role', // The key in the decoded JWT containing the user's role
    passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
    usernameField: 'email', // The key used to provide the username to Feathers client.authenticate
    redirectTo: '/login', // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
};

class App extends Component {
    render() {
        return (
            <Admin
                dataProvider={restClient(rest, restClientOptions)}
                authProvider={authClient(rest, authClientOptions)}>
                {
                    permissions => routes.map((route, key) => {
                        let actionsResource = {};

                        let CList = List;
                        let CShow = Show;
                        let CCreate = Create;
                        let CUpdate = Update;

                        actionsResource.list = CList;
                        actionsResource.show = CShow;
                        actionsResource.create = CCreate;
                        actionsResource.edit = CUpdate;

                        // CUSTOM COMPONENTS...
                        if (route.name === 'registers') {
                            actionsResource.create = undefined;
                            actionsResource.show = undefined;
                            actionsResource.edit = undefined;
                            
                        }
                        if(route.name === 'workers'){
                            actionsResource.list = ListFilter;
                        }
                        // END CUSTOM COMPONENTS
                        /*
                        if (permissions === 'admin') {
                            actionsResource.list = CList;
                            actionsResource.show = CShow;
                            actionsResource.create = CCreate;
                            actionsResource.edit = CUpdate;
                        }
                        */

                        // CUSTOM ROUTES...
                        // END CUSTOM ROUTES

                        return <Resource
                            key={key}
                            name={route.name}
                            icon={route.icon ? route.icon : undefined}
                            options={{label: route.label}} {...actionsResource}/>;
                    })
                }
            </Admin>
        );
    }
}

export default App;
