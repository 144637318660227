import React, {Component} from 'react';
import {
    Create,
    DateInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import * as Models from "../../models";


export default class CreateComponent extends Component {
    render() {
        return (
            <Create {...this.props}>
                <SimpleForm>
                    {
                        Models[this.props.resource.toCamelCase()] && Models[this.props.resource.toCamelCase()][0]
                            .filter(field => field.displayCreate === null || field.displayCreate === undefined || field.displayCreate)
                            .map((field, key) => {
                                switch (field.type) {
                                    case 'NumberField':
                                        return (
                                            <NumberInput key={key} source={field.value}
                                                         label={field.label} {...(field.attrForms ? field.attrForms : {})}/>
                                        );
                                    case 'Date':
                                        return <DateInput key={key} source={field.value}
                                                          label={field.label} {...(field.attrForms ? field.attrForms : {})}/>;
                                    case 'RichText':
                                        return <RichTextInput key={key} source={field.value}
                                                              label={field.label} {...(field.attrForms ? field.attrForms : {})}/>;
                                    case 'ReferenceSelect':
                                        return (
                                            <ReferenceInput key={key} source={field.value} label={field.label}
                                                            reference={field.reference.service}>
                                                <SelectInput source={field.reference.value}/>
                                            </ReferenceInput>
                                        );
                                    case 'ReferenceArrayInput':
                                        return(
                                            <ReferenceArrayInput key={key} source={field.value} reference={field.reference.service} 
                                                                    label={field.label}>
                                                <SelectArrayInput optionText={field.optionText} />
                                            </ReferenceArrayInput>
                                        );
                                    case 'SelectInput':
                                        return(
                                            <SelectInput key= {key} source={field.value} choices={field.choices} optionText={field.displayValue} optionValue={field.valueSelect} label={field.label}/>
                                        );
                                    case 'TextFieldPassword':
                                            return(
                                                <TextInput key={key} label={field.label} source={field.value} type="password" />
                                            );
                                    default:
                                        return <TextInput key={key} source={field.value}
                                                          label={field.label} {...(field.attrForms ? field.attrForms : {})}/>;
                                }
                            })
                    }
                </SimpleForm>
            </Create>
        )
    }
}
