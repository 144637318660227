import feathers from "@feathersjs/client";

const prod = 'https://finsocial-fd-api.e-me.co';
const dev = 'https://finsocial-fd-api.e-me.co';

export const host = process.env.NODE_ENV === 'development' ? dev : prod;

const authOptions = {jwtStrategy: 'jwt', storage: localStorage};

const restConnection = feathers()
    .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
    .configure(feathers.authentication(authOptions));

export const rest = restConnection;
