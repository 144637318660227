import React, {Component} from 'react';
import {
    Datagrid,
    DateField,
    DeleteButton,
    EditButton,
    List,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ImageField
} from 'react-admin';
import * as Models from "../../models";

const FullNameField = ({ record = {} }) =>{
    const data_user = JSON.parse(record.worker_meta_data || '{}');
    return <span>{data_user.first_name} {data_user.last_name} </span>;
}

FullNameField.defaultProps = { label: 'Nombre del trabajador' };


const Identification = ({ record = {} }) =>{
    const data_user = JSON.parse(record.worker_meta_data || '{}');
    return <span>{data_user.document} </span>;
}

Identification.defaultProps = { label: 'Numero de identificación' };

export default class ListComponent extends Component {
    render() {
        return (
            <List {...this.props} >
                {
                    Models[this.props.resource.toCamelCase()] && (
                        <Datagrid>
                            {
                                Models[this.props.resource.toCamelCase()][0]
                                    .filter(field => field.displayList === null || field.displayList === undefined || field.displayList)
                                    .map((field, key) => {
                                        switch (field.type) {
                                            case 'Number':
                                                return (
                                                    <NumberField key={key} source={field.value}
                                                                 label={field.label} {...(field.attr ? field.attr : {})}/>
                                                );
                                            case 'Date':
                                                return (
                                                    <DateField key={key} source={field.value}
                                                               label={field.label} {...(field.attr ? field.attr : {})}/>
                                                );
                                            case 'ReferenceSelect':
                                                return (
                                                    <ReferenceField key={key} source={field.value} label={field.label}
                                                                    reference={field.reference.service}>
                                                        <TextField
                                                            source={field.reference.value} {...(field.reference.attr ? field.reference.attr : {})}/>
                                                    </ReferenceField>
                                                );
                                            case 'ReferenceArrayField':
                                                return (
                                                    <ReferenceArrayField  key={key} label={field.label} reference={field.reference.service} source={field.value}>
                                                        <SingleFieldList>
                                                            <ChipField source={field.reference.value} />
                                                        </SingleFieldList>
                                                    </ReferenceArrayField>
                                                );
                                            case 'ImageField':
                                                return(
                                                    <ImageField source={field.value} title={field.label} />
                                                );
                                            case 'FullNameField':
                                                return(
                                                    <FullNameField/>
                                                );
                                            case 'Identification':
                                                return(
                                                    <Identification />
                                                );
                                            case 'ShowButton':
                                                return(
                                                    <ShowButton/>
                                                );
                                            case 'EditButton':
                                                return(
                                                    <EditButton/>
                                                );
                                            case 'DeleteButton':
                                                return(
                                                    <DeleteButton/>
                                                );
                                            default:
                                                return <TextField key={key} source={field.value}
                                                                  label={field.label} {...(field.attr ? field.attr : {})}/>;
                                        }
                                    })
                            }
                        </Datagrid>
                    )
                }
            </List>
        )
    }
}
