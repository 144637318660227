import React, {Component} from 'react';
import {
    DateField,
    NumberField,
    ReferenceField,
    RichTextField,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField} from 'react-admin';
import * as Models from "../../models";


export default class ShowComponent extends Component {
    render() {
        return (
            <Show {...this.props}>
                {
                    Models[this.props.resource.toCamelCase()] && (
                        <SimpleShowLayout>
                            {
                                Models[this.props.resource.toCamelCase()][0]
                                    .filter(field => field.displayShow === null || field.displayShow === undefined || field.displayShow)
                                    .map((field, key) => {
                                        switch (field.type) {
                                            case 'Number':
                                                return (
                                                    <NumberField key={key} source={field.value}
                                                                 label={field.label} {...(field.attr ? field.attr : {})}/>
                                                );
                                            case 'Date':
                                                return (
                                                    <DateField key={key} source={field.value}
                                                               label={field.label} {...(field.attr ? field.attr : {})}/>
                                                );
                                            case 'RichText':
                                                return <RichTextField key={key} source={field.value}
                                                                      label={field.label} {...field.attr} />;
                                            case 'ReferenceSelect':
                                                return (
                                                    <ReferenceField key={key} source={field.value} label={field.label}
                                                                    reference={field.reference.service}>
                                                        <TextField
                                                            source={field.reference.value} {...(field.reference.attr ? field.reference.attr : {})}/>
                                                    </ReferenceField>
                                                );
                                            case 'ReferenceArrayField':
                                                return (
                                                    <ReferenceArrayField  key={key} label={field.label} reference={field.reference.service} source={field.value}>
                                                        <SingleFieldList>
                                                            <ChipField source={field.optionText} />
                                                        </SingleFieldList>
                                                    </ReferenceArrayField>
                                                );
                                            default:
                                                return <TextField key={key} source={field.value}
                                                                  label={field.label} {...(field.attr ? field.attr : {})}/>;
                                        }
                                    })
                            }
                        </SimpleShowLayout>
                    )
                }
            </Show>
        )
    }
}
